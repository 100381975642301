<tui-loader
  class="text-slate-300"
  [class.invisible]="!isVisible"
  [showLoader]="true"
  size="m"
  [inheritColor]="true"
  data-testid="spinner"
>
  <span class="invisible">oooo</span>
</tui-loader>
