import * as i0 from '@angular/core';
import { Injectable, Directive, InjectionToken, Optional, Self, SkipSelf, inject, Input, ChangeDetectorRef, INJECTOR, signal, TemplateRef, Component, ChangeDetectionStrategy, computed, EventEmitter, ElementRef, ContentChild, Output, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WA_WINDOW } from '@ng-web-apis/common';
import * as i1 from '@taiga-ui/cdk/directives/active-zone';
import { TuiActiveZone } from '@taiga-ui/cdk/directives/active-zone';
import { tuiInjectElement, tuiPointToClientRect, tuiGetActualTarget, tuiIsElement, tuiIsHTMLElement, tuiIsElementEditable, tuiIsTextNode, tuiIsTextfield } from '@taiga-ui/cdk/utils/dom';
import { tuiClamp } from '@taiga-ui/cdk/utils/math';
import { tuiCreateTokenFromFactory, tuiCreateToken, tuiProvide, tuiPure, tuiPx, tuiProvideOptions, tuiIsString, tuiDirectiveBinding } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiDropdownAnimation } from '@taiga-ui/core/animations';
import { TuiDriverDirective, TuiPositionAccessor, tuiFallbackAccessor, TuiRectAccessor, tuiAsRectAccessor, tuiAsVehicle, tuiPositionAccessorFor, tuiRectAccessorFor, tuiAsDriver, TuiDriver, tuiAsPositionAccessor } from '@taiga-ui/core/classes';
import { TuiScrollbar } from '@taiga-ui/core/components/scrollbar';
import { TuiVisualViewportService, TuiPositionService } from '@taiga-ui/core/services';
import { TUI_VIEWPORT, TUI_ANIMATIONS_SPEED, TUI_SELECTION_STREAM } from '@taiga-ui/core/tokens';
import { tuiOverrideOptions, tuiCheckFixedPosition, tuiToAnimationOptions, tuiGetWordRange } from '@taiga-ui/core/utils';
import { PolymorpheusComponent, PolymorpheusTemplate, PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import { Subject, throttleTime, takeWhile, map, merge, filter, fromEvent, distinctUntilChanged, switchMap, of, delay, tap, share, BehaviorSubject, combineLatest } from 'rxjs';
import { __decorate } from 'tslib';
import { tuiZonefreeScheduler, tuiWatch, tuiTypedFromEvent, tuiZoneOptimized } from '@taiga-ui/cdk/observables';
import { TuiPortalService, TuiPortals, tuiAsPortal } from '@taiga-ui/cdk/classes';
import { EMPTY_CLIENT_RECT, TUI_TRUE_HANDLER, CHAR_ZERO_WIDTH_SPACE, CHAR_NO_BREAK_SPACE } from '@taiga-ui/cdk/constants';
import { TUI_IS_IOS, TUI_IS_TOUCH, TUI_RANGE } from '@taiga-ui/cdk/tokens';
import { shouldCall } from '@taiga-ui/event-plugins';
import { DOCUMENT } from '@angular/common';
import * as i1$1 from '@taiga-ui/cdk/directives/obscured';
import { TuiObscured } from '@taiga-ui/cdk/directives/obscured';
import { tuiIsNativeKeyboardFocusable, tuiGetClosestFocusable, tuiIsNativeFocusedIn, tuiGetNativeFocused } from '@taiga-ui/cdk/utils/focus';
import { tuiIsEditingKey } from '@taiga-ui/core/utils/miscellaneous';
const _c0 = a0 => ({
  $implicit: a0
});
function TuiDropdownComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
const _c1 = ["tuiDropdownHost"];
const _c2 = ["*"];
class TuiDropdownDriver extends Subject {
  constructor() {
    super(...arguments);
    this.type = 'dropdown';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiDropdownDriver_BaseFactory;
      return function TuiDropdownDriver_Factory(__ngFactoryType__) {
        return (ɵTuiDropdownDriver_BaseFactory || (ɵTuiDropdownDriver_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownDriver)))(__ngFactoryType__ || TuiDropdownDriver);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiDropdownDriver,
      factory: TuiDropdownDriver.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownDriver, [{
    type: Injectable
  }], null, null);
})();
class TuiDropdownDriverDirective extends TuiDriverDirective {
  constructor() {
    super(...arguments);
    this.type = 'dropdown';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiDropdownDriverDirective_BaseFactory;
      return function TuiDropdownDriverDirective_Factory(__ngFactoryType__) {
        return (ɵTuiDropdownDriverDirective_BaseFactory || (ɵTuiDropdownDriverDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownDriverDirective)))(__ngFactoryType__ || TuiDropdownDriverDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownDriverDirective,
      selectors: [["", "tuiDropdownDriver", ""]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownDriverDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownDriver]'
    }]
  }], null, null);
})();

/**
 * A component to display a dropdown
 */
const TUI_DROPDOWN_COMPONENT = tuiCreateTokenFromFactory(() => TuiDropdownComponent);
const TUI_DROPDOWN_CONTEXT = new InjectionToken('');
class TuiDropdownService extends TuiPortalService {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiDropdownService_BaseFactory;
      return function TuiDropdownService_Factory(__ngFactoryType__) {
        return (ɵTuiDropdownService_BaseFactory || (ɵTuiDropdownService_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownService)))(__ngFactoryType__ || TuiDropdownService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiDropdownService,
      factory: TuiDropdownService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/** Default values for dropdown options */
const TUI_DROPDOWN_DEFAULT_OPTIONS = {
  align: 'left',
  direction: null,
  limitWidth: 'auto',
  maxHeight: 400,
  minHeight: 80,
  offset: 4,
  appearance: ''
};
/**
 * Default parameters for dropdown directive
 */
const TUI_DROPDOWN_OPTIONS = tuiCreateToken(TUI_DROPDOWN_DEFAULT_OPTIONS);
const tuiDropdownOptionsProvider = override => ({
  provide: TUI_DROPDOWN_OPTIONS,
  deps: [[new Optional(), new Self(), TuiDropdownOptionsDirective], [new Optional(), new SkipSelf(), TUI_DROPDOWN_OPTIONS]],
  useFactory: tuiOverrideOptions(override, TUI_DROPDOWN_DEFAULT_OPTIONS)
});
class TuiDropdownOptionsDirective {
  constructor() {
    this.options = inject(TUI_DROPDOWN_OPTIONS, {
      skipSelf: true
    });
    this.align = this.options.align;
    this.appearance = this.options.appearance;
    this.direction = this.options.direction;
    this.limitWidth = this.options.limitWidth;
    this.minHeight = this.options.minHeight;
    this.maxHeight = this.options.maxHeight;
    this.offset = this.options.offset;
  }
  static {
    this.ɵfac = function TuiDropdownOptionsDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownOptionsDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownOptionsDirective,
      selectors: [["", "tuiDropdownAlign", ""], ["", "tuiDropdownAppearance", ""], ["", "tuiDropdownDirection", ""], ["", "tuiDropdownLimitWidth", ""], ["", "tuiDropdownMinHeight", ""], ["", "tuiDropdownMaxHeight", ""], ["", "tuiDropdownOffset", ""]],
      inputs: {
        align: [0, "tuiDropdownAlign", "align"],
        appearance: [0, "tuiDropdownAppearance", "appearance"],
        direction: [0, "tuiDropdownDirection", "direction"],
        limitWidth: [0, "tuiDropdownLimitWidth", "limitWidth"],
        minHeight: [0, "tuiDropdownMinHeight", "minHeight"],
        maxHeight: [0, "tuiDropdownMaxHeight", "maxHeight"],
        offset: [0, "tuiDropdownOffset", "offset"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_DROPDOWN_OPTIONS, TuiDropdownOptionsDirective)])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownOptionsDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownAlign], [tuiDropdownAppearance], [tuiDropdownDirection], [tuiDropdownLimitWidth], [tuiDropdownMinHeight], [tuiDropdownMaxHeight], [tuiDropdownOffset]',
      providers: [tuiProvide(TUI_DROPDOWN_OPTIONS, TuiDropdownOptionsDirective)]
    }]
  }], null, {
    align: [{
      type: Input,
      args: ['tuiDropdownAlign']
    }],
    appearance: [{
      type: Input,
      args: ['tuiDropdownAppearance']
    }],
    direction: [{
      type: Input,
      args: ['tuiDropdownDirection']
    }],
    limitWidth: [{
      type: Input,
      args: ['tuiDropdownLimitWidth']
    }],
    minHeight: [{
      type: Input,
      args: ['tuiDropdownMinHeight']
    }],
    maxHeight: [{
      type: Input,
      args: ['tuiDropdownMaxHeight']
    }],
    offset: [{
      type: Input,
      args: ['tuiDropdownOffset']
    }]
  });
})();
class TuiDropdownPosition extends TuiPositionAccessor {
  constructor() {
    super(...arguments);
    this.options = inject(TUI_DROPDOWN_OPTIONS);
    this.viewport = inject(TUI_VIEWPORT);
    this.type = 'dropdown';
    this.accessor = tuiFallbackAccessor('dropdown')(inject(TuiRectAccessor), inject(TuiDropdownDirective, {
      optional: true
    }));
  }
  getPosition({
    width,
    height
  }) {
    if (!width && !height) {
      this.previous = undefined;
    }
    const hostRect = this.accessor?.getClientRect() ?? EMPTY_CLIENT_RECT;
    const viewportRect = this.viewport.getClientRect();
    const {
      minHeight,
      align,
      direction,
      offset,
      limitWidth
    } = this.options;
    const viewport = {
      top: viewportRect.top - offset,
      bottom: viewportRect.bottom + offset,
      right: viewportRect.right - offset,
      left: viewportRect.left + offset
    };
    const previous = this.previous || direction || 'bottom';
    const available = {
      top: hostRect.top - 2 * offset - viewport.top,
      bottom: viewport.bottom - hostRect.bottom - 2 * offset
    };
    const rectWidth = limitWidth === 'fixed' ? hostRect.width : width;
    const right = Math.max(hostRect.right - rectWidth, offset);
    const left = hostRect.left + width < viewport.right ? hostRect.left : right;
    const position = {
      top: hostRect.top - offset - height,
      bottom: hostRect.bottom + offset,
      right: Math.max(viewport.left, right),
      center: hostRect.left + hostRect.width / 2 + width / 2 < viewport.right ? hostRect.left + hostRect.width / 2 - width / 2 : right,
      left: Math.max(viewport.left, left)
    };
    const better = available.top > available.bottom ? 'top' : 'bottom';
    if (available[previous] > minHeight && direction || available[previous] > height) {
      return [position[previous], position[align]];
    }
    this.previous = better;
    return [position[better], position[align]];
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiDropdownPosition_BaseFactory;
      return function TuiDropdownPosition_Factory(__ngFactoryType__) {
        return (ɵTuiDropdownPosition_BaseFactory || (ɵTuiDropdownPosition_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownPosition)))(__ngFactoryType__ || TuiDropdownPosition);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownPosition,
      selectors: [["", "tuiDropdownPosition", ""]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownPosition, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownPosition]'
    }]
  }], null, null);
})();
class TuiDropdownDirective {
  constructor() {
    this.refresh$ = new Subject();
    this.service = inject(TuiDropdownService);
    this.cdr = inject(ChangeDetectorRef);
    this.sub = this.refresh$.pipe(throttleTime(0, tuiZonefreeScheduler()), takeUntilDestroyed()).subscribe(() => {
      this.ref()?.changeDetectorRef.detectChanges();
      this.ref()?.changeDetectorRef.markForCheck();
    });
    this.el = tuiInjectElement();
    this.type = 'dropdown';
    this.component = new PolymorpheusComponent(inject(TUI_DROPDOWN_COMPONENT), inject(INJECTOR));
    this.ref = signal(null);
  }
  set tuiDropdown(content) {
    this.content = content instanceof TemplateRef ? new PolymorpheusTemplate(content, this.cdr) : content;
  }
  get position() {
    return tuiCheckFixedPosition(this.el) ? 'fixed' : 'absolute';
  }
  ngAfterViewChecked() {
    this.refresh$.next();
  }
  ngOnChanges() {
    if (!this.content) {
      this.toggle(false);
    }
  }
  ngOnDestroy() {
    this.toggle(false);
  }
  getClientRect() {
    return this.el.getBoundingClientRect();
  }
  toggle(show) {
    const ref = this.ref();
    if (show && this.content && !ref) {
      this.ref.set(this.service.add(this.component));
    } else if (!show && ref) {
      this.ref.set(null);
      this.service.remove(ref);
    }
  }
  static {
    this.ɵfac = function TuiDropdownDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownDirective,
      selectors: [["", "tuiDropdown", "", 5, "ng-container", 5, "ng-template"]],
      inputs: {
        tuiDropdown: "tuiDropdown"
      },
      exportAs: ["tuiDropdown"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiDropdownDirective), tuiAsVehicle(TuiDropdownDirective)]), i0.ɵɵHostDirectivesFeature([TuiDropdownDriverDirective, TuiDropdownPosition]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
__decorate([tuiPure], TuiDropdownDirective.prototype, "position", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdown]:not(ng-container):not(ng-template)',
      providers: [tuiAsRectAccessor(TuiDropdownDirective), tuiAsVehicle(TuiDropdownDirective)],
      exportAs: 'tuiDropdown',
      hostDirectives: [TuiDropdownDriverDirective, TuiDropdownPosition]
    }]
  }], null, {
    tuiDropdown: [{
      type: Input
    }],
    position: []
  });
})();

/**
 * @description:
 * This component is used to show template in a portal
 * using default style of white rounded box with a shadow
 */
class TuiDropdownComponent {
  constructor() {
    this.el = tuiInjectElement();
    this.accessor = inject(TuiRectAccessor);
    this.win = inject(WA_WINDOW);
    this.vvs = inject(TuiVisualViewportService);
    this.animation = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.options = inject(TUI_DROPDOWN_OPTIONS);
    this.directive = inject(TuiDropdownDirective);
    this.context = inject(TUI_DROPDOWN_CONTEXT, {
      optional: true
    });
    this.theme = this.directive.el.closest('[tuiTheme]')?.getAttribute('tuiTheme');
    this.sub = inject(TuiPositionService).pipe(takeWhile(() => this.directive.el.isConnected), map(v => this.directive.position === 'fixed' ? this.vvs.correct(v) : v), map(([top, left]) => this.getStyles(top, left)), takeUntilDestroyed()).subscribe({
      next: styles => Object.assign(this.el.style, styles),
      complete: () => this.close()
    });
    this.close = () => this.directive.toggle(false);
  }
  getStyles(top, left) {
    const {
      right
    } = this.el.getBoundingClientRect();
    const {
      maxHeight,
      minHeight,
      offset,
      limitWidth
    } = this.options;
    const {
      innerHeight
    } = this.win;
    const clientRect = this.el.offsetParent?.getBoundingClientRect();
    const {
      position
    } = this.directive;
    const rect = this.accessor.getClientRect();
    const offsetX = position === 'fixed' ? 0 : -(clientRect?.left || 0);
    const offsetY = position === 'fixed' ? 0 : -(clientRect?.top || 0);
    top += offsetY;
    left += offsetX;
    const sided = right <= rect.left || left >= rect.right;
    const isIntersecting = left < rect.right && right > rect.left && top < offsetY + 2 * offset;
    const available = isIntersecting ? rect.top - 2 * offset : offsetY + innerHeight - top - offset;
    return {
      position,
      top: tuiPx(Math.round(Math.max(top, offsetY + offset))),
      left: tuiPx(Math.round(left)),
      maxHeight: sided ? tuiPx(maxHeight) : tuiPx(Math.round(tuiClamp(available, minHeight, maxHeight))),
      width: limitWidth === 'fixed' ? tuiPx(Math.round(rect.width)) : '',
      minWidth: limitWidth === 'min' ? tuiPx(Math.round(rect.width)) : ''
    };
  }
  static {
    this.ɵfac = function TuiDropdownComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiDropdownComponent,
      selectors: [["tui-dropdown"]],
      hostVars: 3,
      hostBindings: function TuiDropdownComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@tuiDropdownAnimation", ctx.animation);
          i0.ɵɵattribute("data-appearance", ctx.options.appearance)("tuiTheme", ctx.theme);
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiPositionService, tuiPositionAccessorFor('dropdown', TuiDropdownPosition), tuiRectAccessorFor('dropdown', TuiDropdownDirective)]), i0.ɵɵHostDirectivesFeature([i1.TuiActiveZone]), i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 4,
      consts: [[1, "t-scroll"], ["class", "t-primitive", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-primitive"]],
      template: function TuiDropdownComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "tui-scrollbar", 0);
          i0.ɵɵtemplate(1, TuiDropdownComponent_div_1_Template, 2, 1, "div", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("polymorpheusOutlet", ctx.directive.content)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx.close));
        }
      },
      dependencies: [PolymorpheusOutlet, TuiScrollbar],
      styles: ["[_nghost-%COMP%]{position:absolute;display:flex;box-shadow:var(--tui-shadow-medium);background:var(--tui-background-elevation-3);border-radius:var(--tui-radius-m);overflow:hidden;border:1px solid var(--tui-border-normal);box-sizing:border-box;max-inline-size:calc(100% - 8px);isolation:isolate;pointer-events:auto}.ng-animating[_nghost-%COMP%]{pointer-events:none}[_nghost-%COMP%]:not([style*=top]){visibility:hidden}.t-scroll[_ngcontent-%COMP%]{flex-grow:1;max-inline-size:100%;overscroll-behavior:none}.t-primitive[_ngcontent-%COMP%]{padding:1rem}"],
      data: {
        animation: [tuiDropdownAnimation]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-dropdown',
      imports: [PolymorpheusOutlet, PolymorpheusTemplate, TuiScrollbar],
      changeDetection: ChangeDetectionStrategy.Default,
      providers: [TuiPositionService, tuiPositionAccessorFor('dropdown', TuiDropdownPosition), tuiRectAccessorFor('dropdown', TuiDropdownDirective)],
      animations: [tuiDropdownAnimation],
      hostDirectives: [TuiActiveZone],
      host: {
        '[@tuiDropdownAnimation]': 'animation',
        '[attr.data-appearance]': 'options.appearance',
        '[attr.tuiTheme]': 'theme'
      },
      template: "<tui-scrollbar class=\"t-scroll\">\n    <div\n        *polymorpheusOutlet=\"directive.content as text; context: {$implicit: close}\"\n        class=\"t-primitive\"\n    >\n        {{ text }}\n    </div>\n</tui-scrollbar>\n",
      styles: [":host{position:absolute;display:flex;box-shadow:var(--tui-shadow-medium);background:var(--tui-background-elevation-3);border-radius:var(--tui-radius-m);overflow:hidden;border:1px solid var(--tui-border-normal);box-sizing:border-box;max-inline-size:calc(100% - 8px);isolation:isolate;pointer-events:auto}:host.ng-animating{pointer-events:none}:host:not([style*=top]){visibility:hidden}.t-scroll{flex-grow:1;max-inline-size:100%;overscroll-behavior:none}.t-primitive{padding:1rem}\n"]
    }]
  }], null, null);
})();
function activeZoneFilter(target) {
  return !this.activeZone.contains(target);
}
const TAP_DELAY = 700;
const MOVE_THRESHOLD = 15;
class TuiDropdownContext extends TuiRectAccessor {
  constructor() {
    super(...arguments);
    this.isIOS = inject(TUI_IS_IOS);
    this.isTouch = inject(TUI_IS_TOUCH);
    this.driver = inject(TuiDropdownDriver);
    this.currentRect = EMPTY_CLIENT_RECT;
    this.longTapTimeout = NaN;
    this.userSelect = computed(() => this.isTouch() ? 'none' : null);
    this.activeZone = inject(TuiActiveZone);
    this.type = 'dropdown';
  }
  getClientRect() {
    return this.currentRect;
  }
  closeDropdown(_element) {
    this.driver.next(false);
    this.currentRect = EMPTY_CLIENT_RECT;
  }
  onContextMenu(x, y) {
    this.currentRect = tuiPointToClientRect(x, y);
    this.driver.next(true);
  }
  onTouchStart(x, y) {
    if (!this.isIOS || !this.isTouch() || this.currentRect !== EMPTY_CLIENT_RECT) {
      return;
    }
    this.currentRect = tuiPointToClientRect(x, y);
    this.longTapTimeout = setTimeout(() => this.driver.next(true), TAP_DELAY);
  }
  onTouchMove(x, y) {
    if (this.isIOS && this.isTouch() && this.currentRect !== EMPTY_CLIENT_RECT && Math.hypot(x - this.currentRect.x, y - this.currentRect.y) > MOVE_THRESHOLD) {
      this.onTouchEnd();
    }
  }
  onTouchEnd() {
    clearTimeout(this.longTapTimeout);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiDropdownContext_BaseFactory;
      return function TuiDropdownContext_Factory(__ngFactoryType__) {
        return (ɵTuiDropdownContext_BaseFactory || (ɵTuiDropdownContext_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownContext)))(__ngFactoryType__ || TuiDropdownContext);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownContext,
      selectors: [["", "tuiDropdownContext", ""]],
      hostVars: 6,
      hostBindings: function TuiDropdownContext_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("touchend.silent.passive", function TuiDropdownContext_touchend_silent_passive_HostBindingHandler() {
            return ctx.onTouchEnd();
          })("touchcancel.silent.passive", function TuiDropdownContext_touchcancel_silent_passive_HostBindingHandler() {
            return ctx.onTouchEnd();
          })("touchmove.silent.passive", function TuiDropdownContext_touchmove_silent_passive_HostBindingHandler($event) {
            return ctx.onTouchMove($event.touches[0].clientX, $event.touches[0].clientY);
          })("touchstart.silent.passive", function TuiDropdownContext_touchstart_silent_passive_HostBindingHandler($event) {
            return ctx.onTouchStart($event.touches[0].clientX, $event.touches[0].clientY);
          })("pointerdown.silent", function TuiDropdownContext_pointerdown_silent_HostBindingHandler($event) {
            return ctx.closeDropdown($event.target);
          }, false, i0.ɵɵresolveDocument)("contextmenu.capture.silent", function TuiDropdownContext_contextmenu_capture_silent_HostBindingHandler($event) {
            return ctx.closeDropdown($event.target);
          }, false, i0.ɵɵresolveDocument)("keydown.esc", function TuiDropdownContext_keydown_esc_HostBindingHandler($event) {
            return ctx.closeDropdown($event.currentTarget);
          }, false, i0.ɵɵresolveDocument)("contextmenu.prevent.stop", function TuiDropdownContext_contextmenu_prevent_stop_HostBindingHandler($event) {
            return ctx.onContextMenu($event.clientX, $event.clientY);
          });
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("user-select", ctx.userSelect())("-webkit-user-select", ctx.userSelect())("-webkit-touch-callout", ctx.userSelect());
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiActiveZone, TuiDropdownDriver, tuiAsDriver(TuiDropdownDriver), tuiAsRectAccessor(TuiDropdownContext)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
__decorate([shouldCall(activeZoneFilter)], TuiDropdownContext.prototype, "closeDropdown", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownContext, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownContext]',
      providers: [TuiActiveZone, TuiDropdownDriver, tuiAsDriver(TuiDropdownDriver), tuiAsRectAccessor(TuiDropdownContext)],
      host: {
        '[style.user-select]': 'userSelect()',
        '[style.-webkit-user-select]': 'userSelect()',
        '[style.-webkit-touch-callout]': 'userSelect()',
        '(touchend.silent.passive)': 'onTouchEnd()',
        '(touchcancel.silent.passive)': 'onTouchEnd()',
        '(touchmove.silent.passive)': 'onTouchMove($event.touches[0].clientX, $event.touches[0].clientY)',
        '(touchstart.silent.passive)': 'onTouchStart($event.touches[0].clientX, $event.touches[0].clientY)',
        '(document:pointerdown.silent)': 'closeDropdown($event.target)',
        '(document:contextmenu.capture.silent)': 'closeDropdown($event.target)',
        '(document:keydown.esc)': 'closeDropdown($event.currentTarget)',
        '(contextmenu.prevent.stop)': 'onContextMenu($event.clientX, $event.clientY)'
      }
    }]
  }], null, {
    closeDropdown: []
  });
})();

/** Default values for hint options */
const TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS = {
  showDelay: 200,
  hideDelay: 500
};
/**
 * Default parameters for dropdown hover directive
 */
const TUI_DROPDOWN_HOVER_OPTIONS = tuiCreateToken(TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS);
function tuiDropdownHoverOptionsProvider(options) {
  return tuiProvideOptions(TUI_DROPDOWN_HOVER_OPTIONS, options, TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS);
}
function shouldClose(event) {
  return 'key' in event && event.key.toLowerCase() === 'escape' && this.tuiDropdownEnabled && !!this.tuiDropdownOpen && !this['dropdown']()?.nextElementSibling;
}
class TuiDropdownOpen {
  constructor() {
    this.directive = inject(TuiDropdownDirective);
    this.el = tuiInjectElement();
    this.obscured = inject(TuiObscured);
    this.dropdown = computed(() => this.directive.ref()?.location.nativeElement);
    this.sub = merge(this.obscured.tuiObscured.pipe(filter(Boolean)), inject(TuiActiveZone).tuiActiveZoneChange.pipe(filter(a => !a)), fromEvent(this.el, 'focusin').pipe(map(tuiGetActualTarget), filter(target => !this.host.contains(target) || !this.directive.ref()))).pipe(tuiWatch(inject(ChangeDetectorRef)), takeUntilDestroyed()).subscribe(() => this.toggle(false));
    this.tuiDropdownEnabled = true;
    this.tuiDropdownOpen = false;
    this.tuiDropdownOpenChange = new EventEmitter();
    // TODO: make it private when all legacy controls will be deleted from @taiga-ui/legacy (5.0)
    this.driver = inject(TuiDropdownDriver);
  }
  ngOnChanges() {
    this.drive();
  }
  toggle(open) {
    if (this.focused && !open) {
      this.host.focus({
        preventScroll: true
      });
    }
    this.update(open);
  }
  onEsc(event) {
    event.preventDefault();
    this.toggle(false);
  }
  onClick(target) {
    if (!this.editable && this.host.contains(target)) {
      this.update(!this.tuiDropdownOpen);
    }
  }
  onArrow(event, up) {
    if (!tuiIsElement(event.target) || !this.host.contains(event.target) || !this.tuiDropdownEnabled) {
      return;
    }
    event.preventDefault();
    this.focusDropdown(up);
  }
  onKeydown({
    key,
    target,
    defaultPrevented
  }) {
    if (defaultPrevented || !tuiIsEditingKey(key) || !this.editable || !this.focused || !tuiIsHTMLElement(target) || tuiIsElementEditable(target) && target !== this.host) {
      return;
    }
    this.update(true);
    this.host.focus({
      preventScroll: true
    });
  }
  get host() {
    const initial = this.dropdownHost?.nativeElement || this.el;
    const focusable = tuiIsNativeKeyboardFocusable(initial) ? initial : tuiGetClosestFocusable({
      initial,
      root: this.el
    });
    return this.dropdownHost?.nativeElement || focusable || this.el;
  }
  get editable() {
    return tuiIsElementEditable(this.host);
  }
  get focused() {
    return tuiIsNativeFocusedIn(this.host) || tuiIsNativeFocusedIn(this.dropdown());
  }
  update(open) {
    if (open && !this.tuiDropdownEnabled) {
      return;
    }
    this.tuiDropdownOpen = open;
    this.tuiDropdownOpenChange.emit(open);
    this.drive();
  }
  drive(open = this.tuiDropdownOpen && this.tuiDropdownEnabled) {
    this.obscured.tuiObscuredEnabled = !!open;
    this.driver.next(!!open);
  }
  focusDropdown(previous) {
    const root = this.dropdown();
    if (!root) {
      this.update(true);
      return;
    }
    const doc = this.el.ownerDocument;
    const child = root.appendChild(doc.createElement('div'));
    const initial = previous ? child : root;
    const focusable = tuiGetClosestFocusable({
      initial,
      previous,
      root
    });
    child.remove();
    focusable?.focus();
  }
  static {
    this.ɵfac = function TuiDropdownOpen_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownOpen)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownOpen,
      selectors: [["", "tuiDropdown", "", "tuiDropdownOpen", ""], ["", "tuiDropdown", "", "tuiDropdownOpenChange", ""]],
      contentQueries: function TuiDropdownOpen_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c1, 5, ElementRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdownHost = _t.first);
        }
      },
      hostBindings: function TuiDropdownOpen_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function TuiDropdownOpen_click_HostBindingHandler($event) {
            return ctx.onClick($event.target);
          })("keydown.arrowDown", function TuiDropdownOpen_keydown_arrowDown_HostBindingHandler($event) {
            return ctx.onArrow($event, false);
          })("keydown.arrowUp", function TuiDropdownOpen_keydown_arrowUp_HostBindingHandler($event) {
            return ctx.onArrow($event, true);
          })("keydown.silent.capture", function TuiDropdownOpen_keydown_silent_capture_HostBindingHandler($event) {
            return ctx.onEsc($event);
          }, false, i0.ɵɵresolveDocument)("keydown.silent", function TuiDropdownOpen_keydown_silent_HostBindingHandler($event) {
            return ctx.onKeydown($event);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        tuiDropdownEnabled: "tuiDropdownEnabled",
        tuiDropdownOpen: "tuiDropdownOpen"
      },
      outputs: {
        tuiDropdownOpenChange: "tuiDropdownOpenChange"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiDropdownDriver, tuiAsDriver(TuiDropdownDriver)]), i0.ɵɵHostDirectivesFeature([i1$1.TuiObscured, {
        directive: i1.TuiActiveZone,
        inputs: ["tuiActiveZoneParent", "tuiActiveZoneParent"],
        outputs: ["tuiActiveZoneChange", "tuiActiveZoneChange"]
      }]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
__decorate([shouldCall(shouldClose)], TuiDropdownOpen.prototype, "onEsc", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownOpen, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdown][tuiDropdownOpen],[tuiDropdown][tuiDropdownOpenChange]',
      providers: [TuiDropdownDriver, tuiAsDriver(TuiDropdownDriver)],
      hostDirectives: [TuiObscured, {
        directive: TuiActiveZone,
        inputs: ['tuiActiveZoneParent'],
        outputs: ['tuiActiveZoneChange']
      }],
      host: {
        '(click)': 'onClick($event.target)',
        '(keydown.arrowDown)': 'onArrow($event, false)',
        '(keydown.arrowUp)': 'onArrow($event, true)',
        '(document:keydown.silent.capture)': 'onEsc($event)',
        '(document:keydown.silent)': 'onKeydown($event)'
      }
    }]
  }], null, {
    dropdownHost: [{
      type: ContentChild,
      args: ['tuiDropdownHost', {
        descendants: true,
        read: ElementRef
      }]
    }],
    tuiDropdownEnabled: [{
      type: Input
    }],
    tuiDropdownOpen: [{
      type: Input
    }],
    tuiDropdownOpenChange: [{
      type: Output
    }],
    onEsc: []
  });
})();
class TuiDropdownHover extends TuiDriver {
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.el = tuiInjectElement();
    this.doc = inject(DOCUMENT);
    this.options = inject(TUI_DROPDOWN_HOVER_OPTIONS);
    this.activeZone = inject(TuiActiveZone);
    this.open = inject(TuiDropdownOpen, {
      optional: true
    });
    this.stream$ = merge(tuiTypedFromEvent(this.doc, 'mouseover').pipe(map(tuiGetActualTarget)), tuiTypedFromEvent(this.doc, 'mouseout').pipe(map(e => e.relatedTarget))).pipe(map(element => tuiIsElement(element) && this.isHovered(element)), distinctUntilChanged(), switchMap(v => of(v).pipe(delay(v ? this.showDelay : this.hideDelay))), tuiZoneOptimized(), tap(hovered => {
      this.hovered = hovered;
      this.open?.toggle(hovered);
    }), share());
    this.showDelay = this.options.showDelay;
    this.hideDelay = this.options.hideDelay;
    this.hovered = false;
    this.type = 'dropdown';
  }
  onClick(event) {
    if (this.hovered && this.open) {
      event.preventDefault();
    }
  }
  isHovered(element) {
    const host = this.dropdownHost?.nativeElement || this.el;
    const hovered = host.contains(element);
    const child = !this.el.contains(element) && this.activeZone.contains(element);
    return hovered || child;
  }
  static {
    this.ɵfac = function TuiDropdownHover_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownHover)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownHover,
      selectors: [["", "tuiDropdownHover", ""]],
      contentQueries: function TuiDropdownHover_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c1, 5, ElementRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdownHost = _t.first);
        }
      },
      hostBindings: function TuiDropdownHover_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click.capture", function TuiDropdownHover_click_capture_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
      },
      inputs: {
        showDelay: [0, "tuiDropdownShowDelay", "showDelay"],
        hideDelay: [0, "tuiDropdownHideDelay", "hideDelay"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiActiveZone, tuiAsDriver(TuiDropdownHover)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownHover, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownHover]',
      providers: [TuiActiveZone, tuiAsDriver(TuiDropdownHover)],
      host: {
        '(click.capture)': 'onClick($event)'
      }
    }]
  }], function () {
    return [];
  }, {
    dropdownHost: [{
      type: ContentChild,
      args: ['tuiDropdownHost', {
        descendants: true,
        read: ElementRef
      }]
    }],
    showDelay: [{
      type: Input,
      args: ['tuiDropdownShowDelay']
    }],
    hideDelay: [{
      type: Input,
      args: ['tuiDropdownHideDelay']
    }]
  });
})();
class TuiDropdownManual {
  constructor() {
    this.driver = inject(TuiDropdownDriver);
    this.tuiDropdownManual = false;
  }
  ngOnChanges() {
    this.driver.next(!!this.tuiDropdownManual);
  }
  static {
    this.ɵfac = function TuiDropdownManual_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownManual)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownManual,
      selectors: [["", "tuiDropdownManual", ""]],
      inputs: {
        tuiDropdownManual: "tuiDropdownManual"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiDropdownDriver, tuiAsDriver(TuiDropdownDriver)]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownManual, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownManual]',
      providers: [TuiDropdownDriver, tuiAsDriver(TuiDropdownDriver)]
    }]
  }], null, {
    tuiDropdownManual: [{
      type: Input
    }]
  });
})();

/**
 * @deprecated TODO: remove in v.5 when legacy controls are dropped
 */
class TuiDropdownOpenLegacy {
  constructor() {
    this.tuiDropdownOpenChange = new BehaviorSubject(false);
  }
  set tuiDropdownOpen(open) {
    this.tuiDropdownOpenChange.next(open);
  }
  static {
    this.ɵfac = function TuiDropdownOpenLegacy_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownOpenLegacy)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownOpenLegacy,
      selectors: [["", "tuiDropdownOpen", "", 3, "tuiDropdown", ""], ["", "tuiDropdownOpenChange", "", 3, "tuiDropdown", ""]],
      inputs: {
        tuiDropdownOpen: "tuiDropdownOpen"
      },
      outputs: {
        tuiDropdownOpenChange: "tuiDropdownOpenChange"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownOpenLegacy, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownOpen]:not([tuiDropdown]),[tuiDropdownOpenChange]:not([tuiDropdown])'
    }]
  }], null, {
    tuiDropdownOpenChange: [{
      type: Output
    }],
    tuiDropdownOpen: [{
      type: Input
    }]
  });
})();
class TuiDropdownPortal {
  constructor() {
    this.template = inject(TemplateRef);
    this.service = inject(TuiDropdownService);
  }
  set tuiDropdown(show) {
    this.viewRef?.destroy();
    if (show) {
      this.viewRef = this.service.addTemplate(this.template);
    }
  }
  ngOnDestroy() {
    this.viewRef?.destroy();
  }
  static {
    this.ɵfac = function TuiDropdownPortal_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownPortal)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownPortal,
      selectors: [["ng-template", "tuiDropdown", ""]],
      inputs: {
        tuiDropdown: "tuiDropdown"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownPortal, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-template[tuiDropdown]'
    }]
  }], null, {
    tuiDropdown: [{
      type: Input
    }]
  });
})();
class TuiDropdownPositionSided extends TuiPositionAccessor {
  constructor() {
    super(...arguments);
    this.options = inject(TUI_DROPDOWN_OPTIONS);
    this.viewport = inject(TUI_VIEWPORT);
    this.vertical = inject(TuiDropdownPosition);
    this.previous = this.options.direction || 'bottom';
    this.tuiDropdownSided = '';
    this.tuiDropdownSidedOffset = 4;
    this.type = 'dropdown';
  }
  getPosition(rect) {
    if (this.tuiDropdownSided === false) {
      return this.vertical.getPosition(rect);
    }
    const {
      height,
      width
    } = rect;
    const hostRect = this.vertical.accessor?.getClientRect() ?? EMPTY_CLIENT_RECT;
    const viewport = this.viewport.getClientRect();
    const {
      direction,
      minHeight,
      offset
    } = this.options;
    const align = this.options.align === 'center' ? 'left' : this.options.align;
    const available = {
      top: hostRect.bottom - viewport.top,
      left: hostRect.left - offset - viewport.left,
      right: viewport.right - hostRect.right - offset,
      bottom: viewport.bottom - hostRect.top
    };
    const position = {
      top: hostRect.bottom - height + this.tuiDropdownSidedOffset + 1,
      left: hostRect.left - width - offset,
      right: hostRect.right + offset,
      bottom: hostRect.top - this.tuiDropdownSidedOffset - 1 // 1 for border
    };
    const better = available.top > available.bottom ? 'top' : 'bottom';
    const maxLeft = available.left > available.right ? position.left : position.right;
    const left = available[align] > width ? position[align] : maxLeft;
    if (available[this.previous] > minHeight && direction || this.previous === better) {
      return [position[this.previous], left];
    }
    this.previous = better;
    return [position[better], left];
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiDropdownPositionSided_BaseFactory;
      return function TuiDropdownPositionSided_Factory(__ngFactoryType__) {
        return (ɵTuiDropdownPositionSided_BaseFactory || (ɵTuiDropdownPositionSided_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownPositionSided)))(__ngFactoryType__ || TuiDropdownPositionSided);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownPositionSided,
      selectors: [["", "tuiDropdownSided", ""]],
      inputs: {
        tuiDropdownSided: "tuiDropdownSided",
        tuiDropdownSidedOffset: "tuiDropdownSidedOffset"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiDropdownPosition, tuiAsPositionAccessor(TuiDropdownPositionSided)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownPositionSided, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownSided]',
      providers: [TuiDropdownPosition, tuiAsPositionAccessor(TuiDropdownPositionSided)]
    }]
  }], null, {
    tuiDropdownSided: [{
      type: Input
    }],
    tuiDropdownSidedOffset: [{
      type: Input
    }]
  });
})();
class TuiDropdownSelection extends TuiDriver {
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.doc = inject(DOCUMENT);
    this.vcr = inject(ViewContainerRef);
    this.dropdown = inject(TuiDropdownDirective);
    this.el = tuiInjectElement();
    this.handler$ = new BehaviorSubject(TUI_TRUE_HANDLER);
    this.stream$ = combineLatest([this.handler$, inject(TUI_SELECTION_STREAM).pipe(map(() => this.getRange()), distinctUntilChanged((x, y) => x.startOffset === y.startOffset && x.endOffset === y.endOffset && x.commonAncestorContainer === y.commonAncestorContainer))]).pipe(map(([handler, range]) => {
      const contained = this.el.contains(range.commonAncestorContainer);
      this.range = contained && tuiIsTextNode(range.commonAncestorContainer) ? range : this.range;
      return contained && handler(this.range) || this.inDropdown(range);
    }));
    this.range = inject(TUI_RANGE);
    this.position = 'selection';
    this.type = 'dropdown';
  }
  set tuiDropdownSelection(visible) {
    if (!tuiIsString(visible)) {
      this.handler$.next(visible);
    }
  }
  getClientRect() {
    switch (this.position) {
      case 'tag':
        {
          const {
            commonAncestorContainer
          } = this.range;
          const element = tuiIsElement(commonAncestorContainer) ? commonAncestorContainer : commonAncestorContainer.parentNode;
          return element && tuiIsElement(element) ? element.getBoundingClientRect() : EMPTY_CLIENT_RECT;
        }
      case 'word':
        return tuiGetWordRange(this.range).getBoundingClientRect();
      default:
        return this.range.getBoundingClientRect();
    }
  }
  ngOnDestroy() {
    if (this.ghost) {
      this.vcr.element.nativeElement.removeChild(this.ghost);
    }
  }
  getRange() {
    const active = tuiGetNativeFocused(this.doc);
    const selection = this.doc.getSelection();
    const range = active && tuiIsTextfield(active) && this.el.contains(active) ? this.veryVerySadInputFix(active) : selection?.rangeCount && selection.getRangeAt(0) || this.range;
    return range.cloneRange();
  }
  /**
   * Check if given range is at least partially inside dropdown
   */
  inDropdown(range) {
    const {
      startContainer,
      endContainer
    } = range;
    const inDropdown = this.boxContains(range.commonAncestorContainer);
    const hostToDropdown = this.boxContains(endContainer) && this.el.contains(startContainer);
    const dropdownToHost = this.boxContains(startContainer) && this.el.contains(endContainer);
    return inDropdown || hostToDropdown || dropdownToHost;
  }
  /**
   * Check if Node is inside dropdown
   */
  boxContains(node) {
    return !!this.dropdown.ref()?.location.nativeElement.contains(node);
  }
  veryVerySadInputFix(element) {
    const {
      ghost = this.initGhost(element)
    } = this;
    const {
      top,
      left,
      width,
      height
    } = element.getBoundingClientRect();
    const {
      selectionStart,
      selectionEnd,
      value
    } = element;
    const range = this.doc.createRange();
    const hostRect = this.el.getBoundingClientRect();
    ghost.style.top = tuiPx(top - hostRect.top);
    ghost.style.left = tuiPx(left - hostRect.left);
    ghost.style.width = tuiPx(width);
    ghost.style.height = tuiPx(height);
    ghost.textContent = CHAR_ZERO_WIDTH_SPACE + value + CHAR_NO_BREAK_SPACE;
    range.setStart(ghost.firstChild, selectionStart || 0);
    range.setEnd(ghost.firstChild, selectionEnd || 0);
    return range;
  }
  /**
   * Create an invisible DIV styled exactly like input/textarea element inside directive
   */
  initGhost(element) {
    const ghost = this.doc.createElement('div');
    const {
      font,
      letterSpacing,
      textTransform,
      padding
    } = getComputedStyle(element);
    ghost.style.position = 'absolute';
    ghost.style.pointerEvents = 'none';
    ghost.style.opacity = '0';
    ghost.style.whiteSpace = 'pre-wrap';
    ghost.style.font = font;
    ghost.style.letterSpacing = letterSpacing;
    ghost.style.textTransform = textTransform;
    ghost.style.padding = padding;
    this.vcr.element.nativeElement.appendChild(ghost);
    this.ghost = ghost;
    return ghost;
  }
  static {
    this.ɵfac = function TuiDropdownSelection_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiDropdownSelection)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiDropdownSelection,
      selectors: [["", "tuiDropdownSelection", ""]],
      inputs: {
        position: [0, "tuiDropdownSelectionPosition", "position"],
        tuiDropdownSelection: "tuiDropdownSelection"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiDropdownSelection), tuiAsRectAccessor(TuiDropdownSelection)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownSelection, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownSelection]',
      providers: [tuiAsDriver(TuiDropdownSelection), tuiAsRectAccessor(TuiDropdownSelection)]
    }]
  }], function () {
    return [];
  }, {
    position: [{
      type: Input,
      args: ['tuiDropdownSelectionPosition']
    }],
    tuiDropdownSelection: [{
      type: Input
    }]
  });
})();
const TuiDropdown = [TuiDropdownOptionsDirective, TuiDropdownDriverDirective, TuiDropdownDirective, TuiDropdownComponent, TuiDropdownOpen, TuiDropdownOpenLegacy, TuiDropdownPortal, TuiDropdownManual, TuiDropdownHover, TuiDropdownContext, TuiDropdownPosition, TuiDropdownPositionSided, TuiDropdownSelection];
function tuiDropdown(value) {
  return tuiDirectiveBinding(TuiDropdownDirective, 'tuiDropdown', value, {});
}
function tuiDropdownOpen() {
  const open = tuiDirectiveBinding(TuiDropdownOpen, 'tuiDropdownOpen', false, {});
  inject(TuiDropdownOpen).tuiDropdownOpenChange.pipe(takeUntilDestroyed()).subscribe(value => open.set(value));
  return open;
}

/**
 * Host element for dynamically created portals, for example using {@link TuiDropdownDirective}.
 */
class TuiDropdowns extends TuiPortals {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiDropdowns_BaseFactory;
      return function TuiDropdowns_Factory(__ngFactoryType__) {
        return (ɵTuiDropdowns_BaseFactory || (ɵTuiDropdowns_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdowns)))(__ngFactoryType__ || TuiDropdowns);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiDropdowns,
      selectors: [["tui-dropdowns"]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsPortal(TuiDropdownService)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 3,
      vars: 0,
      consts: [["viewContainer", ""]],
      template: function TuiDropdowns_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
          i0.ɵɵelementContainer(1, null, 0);
        }
      },
      styles: ["[_nghost-%COMP%]{display:block;height:100%;isolation:isolate}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdowns, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-dropdowns',
      template: `
        <ng-content></ng-content>
        <ng-container #viewContainer></ng-container>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsPortal(TuiDropdownService)],
      styles: [":host{display:block;height:100%;isolation:isolate}\n"]
    }]
  }], null, null);
})();
class TuiWithDropdownOpen {
  static {
    this.ɵfac = function TuiWithDropdownOpen_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiWithDropdownOpen)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiWithDropdownOpen,
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: TuiDropdownOpen,
        inputs: ["tuiDropdownOpen", "open"],
        outputs: ["tuiDropdownOpenChange", "openChange"]
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiWithDropdownOpen, [{
    type: Directive,
    args: [{
      standalone: true,
      hostDirectives: [{
        directive: TuiDropdownOpen,
        inputs: ['tuiDropdownOpen: open'],
        outputs: ['tuiDropdownOpenChange: openChange']
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_DROPDOWN_COMPONENT, TUI_DROPDOWN_CONTEXT, TUI_DROPDOWN_DEFAULT_OPTIONS, TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS, TUI_DROPDOWN_HOVER_OPTIONS, TUI_DROPDOWN_OPTIONS, TuiDropdown, TuiDropdownComponent, TuiDropdownContext, TuiDropdownDirective, TuiDropdownDriver, TuiDropdownDriverDirective, TuiDropdownHover, TuiDropdownManual, TuiDropdownOpen, TuiDropdownOpenLegacy, TuiDropdownOptionsDirective, TuiDropdownPortal, TuiDropdownPosition, TuiDropdownPositionSided, TuiDropdownSelection, TuiDropdownService, TuiDropdowns, TuiWithDropdownOpen, tuiDropdown, tuiDropdownHoverOptionsProvider, tuiDropdownOpen, tuiDropdownOptionsProvider };
