import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Status } from '../../models/enums/status.enum';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'pxc-loader',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() dark = false;
  @Input() fullscreen = false;
  @Input() useLogo = false;
  @Input() status: Status.LOADING | Status.ERROR = Status.LOADING;

  Status = Status;
}
