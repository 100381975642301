import { inject, Injectable } from '@angular/core';
import { TeledepositApiService } from '@features/teledeposit/api/teledeposit.api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormAnswers } from '@pixacare/pxc-ts-core';
import {
  BehaviorSubject,
  first,
  map,
  Observable,
  shareReplay,
  switchMap
} from 'rxjs';
import { fromDepositInfoResponse, toCreateDepositSequenceRequest } from '../api/adapters/teledeposit.adapter';
import { TeledepositSequence } from '../models/teledeposit-sequence';

@UntilDestroy()
@Injectable()
export class TeledepositService {

  private readonly teledepositApiService = inject(TeledepositApiService);
  
  private readonly teledepositSequenceSubject = new BehaviorSubject<TeledepositSequence>({});
  readonly teledepositSequence$ = this.teledepositSequenceSubject.asObservable().pipe(untilDestroyed(this));

  readonly images$ = this.teledepositSequence$.pipe(map(({ images }) => images));
  readonly formAnswers$ = this.teledepositSequence$.pipe(map(({ formAnswers }) => formAnswers));

  readonly depositInfo$ = this.teledepositApiService.getDepositInfo().pipe(
    map(fromDepositInfoResponse), 
    shareReplay(1), 
    untilDestroyed(this)
  );
  readonly patientForm$ = this.teledepositApiService.getPatientForm().pipe(
    shareReplay(1),
    untilDestroyed(this)
  );

  saveImages(images: File[]): void {
    this.teledepositSequenceSubject.next({
      ...this.teledepositSequenceSubject.getValue(),
      images,
    });
  }

  saveFormAnswers(formAnswers: FormAnswers) {
    this.teledepositSequenceSubject.next({
      ...this.teledepositSequenceSubject.getValue(),
      formAnswers,
    });
  }

  createTeledeposit(): Observable<void> {
    return this.teledepositSequenceSubject.pipe(
      first(),
      switchMap(({ images, formAnswers }) =>
        this.teledepositApiService.createDepositSequence(
          toCreateDepositSequenceRequest({ images, formAnswers })
        )
      ),
    );
  }

  clear() {
    this.teledepositSequenceSubject.next({});
  }
}
