import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PatientForm } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { toFormData } from './adapters/teledeposit.adapter';
import { CreateDepositSequenceRequest } from './models/requests/create-deposit-sequence.request';
import { DepositInfoResponse } from './models/responses/deposit-info.response';

@Injectable({
  providedIn: 'root',
})
export class TeledepositApiService {

  private httpClient = inject(HttpClient);
  private serviceBaseUrl = `${environment.apiBaseUrl}/deposit`;

  public getPatientForm(): Observable<PatientForm> {
    return this.httpClient.get<PatientForm>(`${this.serviceBaseUrl}/form`);
  }

  public createDepositSequence({ sequenceImages, formAnswer }: CreateDepositSequenceRequest): Observable<void> {
    const formData = toFormData({ sequenceImages, formAnswer });
    return this.httpClient.post<void>(
      `${this.serviceBaseUrl}/sequence`,
      formData,
    );
  }

  public getDepositInfo(): Observable<DepositInfoResponse> {
    return this.httpClient.get<DepositInfoResponse>(`${this.serviceBaseUrl}/info`);
  }
}
