import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiIcon } from '@taiga-ui/core';
import { TuiBadge } from '@taiga-ui/kit';
import { Size } from '../../models/enums/size.enum';

@Component({
  selector: 'pxc-title',
  standalone: true,
  imports: [TuiIcon, TuiBadge],
  templateUrl: './title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent {
  @Input() icon?: string;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() counter?: number;
  @Input() maxCount?: number | string;
  @Input() titleSize = Size.MEDIUM;

  Size = Size;
}
