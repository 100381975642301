import { TeledepositInfo } from '@features/teledeposit/models/teledeposit-info';
import { TeledepositSequence } from '@features/teledeposit/models/teledeposit-sequence';
import { CreateDepositSequenceRequest } from "../models/requests/create-deposit-sequence.request";
import { DepositInfoResponse } from '../models/responses/deposit-info.response';

export const toCreateDepositSequenceRequest = ({ images, formAnswers }: TeledepositSequence): CreateDepositSequenceRequest => {
  return {
    sequenceImages: images ?? [],
    formAnswer: { answers: formAnswers ?? {} }
  };
}

export const fromCreateDepositSequenceRequest = ({ sequenceImages, formAnswer }: CreateDepositSequenceRequest): TeledepositSequence => {
  return {
    images: sequenceImages,
    formAnswers: formAnswer.answers
  };
}

export const toDepositInfoReponse = ({ clientName, createdByFullName, clientImageUri, hasPatientForm }: TeledepositInfo): DepositInfoResponse => {
  return { 
    clientName, 
    createdByFullName, 
    clientImageUri, 
    hasPatientForm 
  };
}

export const fromDepositInfoResponse = ({ clientName, createdByFullName, clientImageUri, hasPatientForm }: DepositInfoResponse): TeledepositInfo => {
  return { 
    clientName, 
    createdByFullName, 
    clientImageUri, 
    hasPatientForm 
  };
}

export const toFormData = ({ sequenceImages, formAnswer }: CreateDepositSequenceRequest): FormData => {
  const formData = new FormData();
  sequenceImages.forEach((sequenceImage) => {
    formData.append(`sequenceImages`, sequenceImage);
  });
  formData.append('formAnswer', JSON.stringify(formAnswer));
  return formData;
}