<div class="flex items-center gap-3 my-2">
  @if (icon) {
    <tui-icon [icon]="icon" data-testid="icon"></tui-icon>
  }

  <div>
    @switch (titleSize) {
      @case (Size.LARGE) {
        <h1>{{ title }}</h1>
      }
      @case (Size.MEDIUM) {
        <h4>{{ title }}</h4>
      }
      @case (Size.SMALL) {
        <h6>{{ title }}</h6>
      }
      @default {
        <p>{{ title }}</p>
      }
    }
    @if (!!subtitle) {
      <h6 class="text-slate-600">{{ subtitle }}</h6>
    }
  </div>

  @if (!!counter) {
    <tui-badge data-testid="counter" class="!font-bold mt-1" size="l"
      >{{ counter }}{{ maxCount ? "/" + maxCount : "" }}</tui-badge
    >
  }
</div>
